import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { AiOutlineClose } from 'react-icons/ai'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'
import mobilescreenshot from '../assets/images/mobilescreenshot_dual.png'
import mobileappstore from '../assets/images/mobile_appstore.png'
import mobilegoogleplay from '../assets/images/mobile_googleplay.png'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class MobileAppPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
      overlay: true,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'EdgeForecaster Mobile App'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    const mobilehighlight = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Grid
                className="pagesection_text"
                style={{ marginTop: '10vmin' }}
                container
                spacing={0}
              >
                <Grid item xs={12}>
                  <p>
                    Monitor your market edge wherever you are in the world
                    through the EdgeForecaster mobile app. Track strategy
                    metrics, check quant stats, get notified of forecast
                    changes, and much more. Once the EdgeForecaster Automated
                    Trading system is released, the mobile app will be central
                    to launching and monitoring trading bots.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Released in March 2022 on the App Store and Google Play, the
                    EdgeForecaster Mobile App will give users access to their
                    market edge, anywhere and anytime they need it.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                      <a href="/mobileapp" className="mobileappstorelink">
                        <img
                          className="mobiledownloadimg"
                          src={mobileappstore}
                          alt="intro"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                      <a href="/mobileapp" className="mobileappstorelink">
                        <img
                          className="mobiledownloadimg"
                          src={mobilegoogleplay}
                          alt="intro"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={4} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={5}>
              <div className="mobileappscreenshot_cont">
                <img
                  className="mobileappscreenshot"
                  src={mobilescreenshot}
                  alt="intro"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
    const mobilehighlightMob = (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="mobileappscreenshot_cont">
                <img
                  className="mobileappscreenshot"
                  src={mobilescreenshot}
                  alt="intro"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                  <p>
                    Monitor your market edge wherever you are in the world
                    through the EdgeForecaster mobile app. Track strategy
                    metrics, check quant stats, get notified of forecast
                    changes, and much more. Once the EdgeForecaster Automated
                    Trading system is released, the mobile app will be central
                    to launching and monitoring trading bots.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Released in March 2022 on the App Store and Google Play, the
                    EdgeForecaster Mobile App will give users access to their
                    market edge, anywhere and anytime they need it.
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                      <a href="/mobileapp" className="mobileappstorelink">
                        <img
                          className="mobiledownloadimg"
                          src={mobileappstore}
                          alt="intro"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={2} />
                    <Grid item xs={8}>
                      <a href="/mobileapp" className="mobileappstorelink">
                        <img
                          className="mobiledownloadimg"
                          src={mobilegoogleplay}
                          alt="intro"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
    console.log(isMobile)
    console.log(pagewidth)

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                {isMobile ? mobilehighlightMob : mobilehighlight}
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(MobileAppPage)
export const MobileAppPageQuery = graphql`
  query MobileAppPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
